import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "poppins-font"; 
import "@fontsource/libre-baskerville";

const DefaultLayout = ({ children }) => {
  return (
    <div className="flex flex-col text-gray-900">
      <Header />
      <div className="flex flex-col m-auto">
      <div className="pt-5">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default DefaultLayout;