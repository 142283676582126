import React, { useState } from "react";
import Logo from '../images/foerderkreisLogo.svg';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import HamburgerMenu from "react-hamburger-menu";
import { Link } from "gatsby";

const homeSections = [
  {
    name: "hme",
    title: "Home",
    link: "#",
  },
  {
    name: "aktuelles",
    title: "Aktuelles",
    link: "#",
  },
  {
    name: "rueckblicke",
    title: "Rückblicke",
    link: "#",
  },
  {
    name: "editionen",
    title: "Editionen",
    link: "#",
  },
  {
    name: "ueberUns",
    title: "Über uns",
    link: "#",
  },
  {
    name: "newsletter",
    title: "Newsletter",
    link: "#newsletter",
  },
  {
    name: "mitgliedschaft",
    title: "Mitgliedschaft",
    link: "#",
  },
  {
    name: "kontakt",
    title: "Kontakt",
    link: "#",
  },
];


const BurgerMenu = () => {
  const [showMenu, toggleMenu] = useState(false);
  return (
    <nav className="fixed right-10 rounded-lg bg-white shadow-md p-5">
      <div
        className="cursor-pointer ">
        <HamburgerMenu
          isOpen={showMenu}
          menuClicked={() => toggleMenu(!showMenu)}
          width={18}
          height={15}
          strokeWidth={1}
          rotate={0}
          color='black'
          borderRadius={0}
          animationDuration={0.5}
        />
        <div className={`navMenu bg-white p-8 absolute mt-3  right-0 text-center shadow-md ${showMenu ? "" : "hidden"}`}>
          <ul>
            {
              homeSections.map((section) => <li onClick={() => toggleMenu(false)} key={"nav-" + section.title} 
              className="hover:text-blue-600 p-4">
                <AnchorLink title={section.name} to={`/#${section.name}`}>{section.title}</AnchorLink>
              </li>)
            }
          </ul>
        </div>
      </div>
    </nav>
  )
}

const Header = () => {
  return (

    <div
      className="flex items-center 
    justify-between
    pt-4 pb-4
    pl-20
    pr-10 h-auto bg-white min-w-full">
      <Link to="/"><img
        alt="Logo Förderkreis"
        className="w-32"
        src={Logo}
      />
      </Link>
      <BurgerMenu />
    </div>

  );
};

export default Header;
