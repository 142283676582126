import * as React from "react";
import DefaultLayout from "../components/DefaultLayout";

// markup
const ImpressumPage = () => {
  return (
    <DefaultLayout>
      <title>Impressum</title>
      <p>Impressum Content</p>
    </DefaultLayout>
  );
};

export default ImpressumPage;
