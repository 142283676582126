import * as React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <div className="text-center">
      <Link to="/impressum">Impressum</Link> |{" "}
      <Link to="/datenschutz">Datenschutzerklärung</Link>
    </div>
  );
};

export default Footer;
